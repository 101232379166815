/* eslint-disable no-shadow */
import services from '@services';
import upperFirst from 'lodash/upperFirst';
import formatDate from '@utils/time-formats';

const state = {
  loading: false,
  loadingStatus: false,
  mission: {},
  status: {},
  statusTable: [],
};

const getters = {
  loadingMission: (state) => state.loading,
  loadingMissionStatus: (state) => state.loadingStatus,
  currentMission: (state) => state.mission,
  currentMissionStatus: (state) => state.status,
  missionStatusTable: (state) => state.statusTable,
};

const actions = {
  getMission({ commit }, projectId) {
    commit('loading_mission', true);
    return new Promise((resolve, reject) => {
      services.missions.get(projectId)
        .then((res) => {
          const { items } = res.data;
          const [mission] = items;
          commit('fetch_mission', mission);
          commit('loading_mission', false);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_mission', false);
          reject(err);
        });
    });
  },
  getStatus({ commit }, { projectId, missionId }) {
    commit('loading_mission_status', true);
    return new Promise((resolve, reject) => {
      services.missions.getStatus(projectId, missionId)
        .then((res) => {
          const [missionStatus] = res.data.items;
          commit('fetch_mission_status', missionStatus);
          commit('format_mission_status_table', missionStatus);
          commit('loading_mission_status', false);
          resolve(missionStatus);
        })
        .catch((err) => {
          commit('loading_mission_status', false);
          reject(err);
        });
    });
  },
};

const mutations = {
  loading_mission(state, loading) {
    state.loading = loading;
  },
  loading_mission_status(state, loading) {
    state.loadingStatus = loading;
  },
  fetch_mission(state, mission) {
    state.mission = mission;
  },
  fetch_mission_status(state, status) {
    state.status = status;
  },
  format_mission_status_table(state, status) {
    const fields = Object.keys(status).filter((field) => !field.split('_').includes('id'));
    const rows = fields.map((field) => {
      const fieldName = upperFirst(field.split('_').join(' '));
      const row = {
        key: field,
        name: fieldName,
      };

      row.value = (field === 'created_at' || field === 'updated_at') ? formatDate(status[field], 'YEAR_MONTH_DATE') : upperFirst(status[field]);
      return row;
    });

    state.statusTable = rows;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
